import { useState } from 'react';
import { HomeDesktop } from '../components/HomeDesktop';
import { HomeMobile } from '../components/HomeMobile';
import CustomQRCode from '../components/CustomQRCode';
import courseImg from '../assets/course.jpg';
import cuisineImg from '../assets/cuisine.jpg';
import mainImg from '../assets/main-splash.jpg';
import recettesImg from '../assets/Recettes.jpg';
import planificateurImg from '../assets/planificateur.jpg';
import saisonImg from '../assets/saison.jpg';
import googlePlayImg from '../assets/google_play_fr.webp';
import splashMobileImg from '../assets/splashmobile.jpg';
import seasonLogo from '../assets/season512.png';
import { ImageType } from '../types/ImageTypes';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination, Navigation } from 'swiper/modules';

import './Home.css';

const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.kiatoweb.season';

function Home() {
  const [selectedImage, setSelectedImage] = useState<ImageType | null>(null);

  const mainImage: ImageType = { 
    src: mainImg, 
    alt: 'Main', 
    isLandscape: true 
  };
  
  const otherImages: ImageType[] = [
    { src: recettesImg, alt: "Recettes", isLandscape: false },
    { src: courseImg, alt: "Course", isLandscape: false },
    { src: cuisineImg, alt: "Cuisine", isLandscape: false },
    { src: saisonImg, alt: "Saison", isLandscape: false },
    { src: planificateurImg, alt: "Planificateur", isLandscape: false },
  ];

  const handleImageClick = (image: ImageType) => {
    if (window.innerWidth >= 640) {
      setSelectedImage(image);
    }
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="flex flex-col items-center w-full overflow-x-hidden">
      <HomeMobile 
        mainImage={mainImage}
        splashMobileImg={splashMobileImg}
        googlePlayImg={googlePlayImg}
        playStoreUrl={PLAY_STORE_URL}
      />
      <HomeDesktop 
        mainImage={mainImage}
        googlePlayImg={googlePlayImg}
        playStoreUrl={PLAY_STORE_URL}
      />

      {/* QR Code Section
      <div className="w-full max-w-7xl mx-auto px-4 md:px-8 mt-16">
        <h3 className="text-2xl font-bold text-center mb-8">Scannez pour télécharger Season</h3>
        <div className="flex justify-center">
          <CustomQRCode
            value="https://season-app.com/beta"
            logoUrl={seasonLogo}
            size={300}
          />
        </div>
      </div> */}

      {/* Carousel Section */}
      <div id="images" className="w-full max-w-7xl mx-auto px-4 md:px-8 mt-16">
        <h3 className="text-2xl font-bold text-center mb-8">Découvrez Season en images</h3>
        <Swiper
          slidesPerView={1}
  
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {otherImages.map((image, index) => (
            <SwiperSlide key={index} onClick={() => handleImageClick(image)}>
              <img
                src={image.src}
                alt={image.alt}
                className="swiper-slide-img"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Modal for zoomed image */}
      <div 
        className={`modal ${selectedImage ? 'open' : ''}`}
        onClick={handleCloseModal}
      >
        {selectedImage && (
          <img
            src={selectedImage.src}
            alt={selectedImage.alt}
            className="modal__image"
            onClick={(e) => e.stopPropagation()}
          />
        )}
      </div>
    </div>
  );
}

export default Home;
