import styled from 'styled-components';
import { Link, NavLink as RouterNavLink } from 'react-router-dom';
import { useState } from 'react';
import logo from '../assets/season512.png';
import { FaPinterest } from 'react-icons/fa';

const Nav = styled.nav<{ isMenuOpen: boolean }>`
  padding: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme, isMenuOpen }) => 
    isMenuOpen 
      ? theme.card 
      : `${theme.card}99`};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
  box-shadow: ${({ isMenuOpen }) => 
    isMenuOpen 
      ? '0 2px 4px rgba(0,0,0,0.1)' 
      : 'none'};
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
`;

const StyledNavLink = styled(RouterNavLink)`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: block;
  
  &.active {
    color: ${({ theme }) => theme.primary};
  }
  
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const MobileLogoButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  padding: 0.25rem;

  @media (min-width: 768px) {
    display: none;
  }
`;

const MobileLogo = styled.img`
  height: 40px;
  width: 40px;
  object-fit: contain;
  display: block;
`;

const MenuContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 250px;
  background-color: ${({ theme }) => theme.card};
  padding-top: 4rem;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isOpen }) => isOpen ? 'translateX(0)' : 'translateX(100%)'};
  z-index: 30;

  @media (min-width: 768px) {
    display: none;
  }
`;

const DesktopLinks = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex: 1;
  }
`;

const NavContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
`;

const Logo = styled.img`
  height: 40px;
  width: auto;
  display: block;
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  
  &:hover {
    background: none;
  }
`;

const SocialLink = styled.a`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const SocialLinksContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: auto;
`;

const MenuDivider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.border};
  margin: 0.5rem 1rem;
`;

const MenuLink = styled(RouterNavLink)`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: block;
  font-size: 0.9rem;
  
  &:hover {
    background-color: ${({ theme }) => theme.primary};
  }
`;

const MainMenuLink = styled(RouterNavLink)`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: block;
  font-size: 1.1rem;
  font-weight: 500;
  
  &:hover {
    background-color: ${({ theme }) => theme.primary};
  }
`;

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setIsMenuOpen(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <Nav isMenuOpen={isMenuOpen}>
        <MobileLogoButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <MobileLogo src={logo} alt="Season" />
        </MobileLogoButton>
        <NavContainer>
          <DesktopLinks>
            <LogoLink to="/" onClick={handleMenuClick}>
              <Logo src={logo} alt="Season" />
            </LogoLink>
            <StyledNavLink 
              to="/guide" 
              onClick={handleMenuClick}
              style={{
                fontSize: '1.2rem',
                fontWeight: '500',
              }}
            >Guide</StyledNavLink>
          </DesktopLinks>
          <SocialLinksContainer>
            <SocialLink 
              href="https://fr.pinterest.com/SeasonListesEtRecettes/" 
              target="_blank" 
              rel="noopener noreferrer"
              aria-label="Visit our Pinterest"
            >
              <FaPinterest />
            </SocialLink>
          </SocialLinksContainer>
        </NavContainer>
      </Nav>
      
      <MenuContainer isOpen={isMenuOpen}>
        <MainMenuLink to="/" onClick={handleMenuClick}>Accueil</MainMenuLink>
        <MainMenuLink to="/guide" onClick={handleMenuClick}>Guide</MainMenuLink>
        <MenuDivider />
        <MenuLink to="/mentions-legales" onClick={handleMenuClick}>Mentions légales</MenuLink>
        <MenuLink to="/conditions-generales" onClick={handleMenuClick}>Conditions générales</MenuLink>
        <MenuLink to="/politique-confidentialite" onClick={handleMenuClick}>Politique de confidentialité</MenuLink>
      </MenuContainer>

      {isMenuOpen && (
        <div 
          onClick={() => setIsMenuOpen(false)}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(0,0,0,0.5)',
            zIndex: 20
          }}
        />
      )}
    </>
  );
}

export default Navbar;
