import { ImageType } from '../types/ImageTypes';
import { FaSeedling } from 'react-icons/fa';

interface HomeMobileProps {
  mainImage: ImageType;
  splashMobileImg: string;
  googlePlayImg: string;
  playStoreUrl: string;
}

export function HomeMobile({ mainImage, splashMobileImg, googlePlayImg, playStoreUrl }: HomeMobileProps) {
  return (
    <div className="flex flex-col w-full md:hidden">
      <div className="relative h-screen">
        <img
          src={splashMobileImg}
          alt={mainImage.alt}
          className="w-auto h-full object-cover mx-auto"
        />
        <div className="absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full px-4">
          <h1 className="text-4xl font-bold text-center mb-4">
            <span className="text-[#7CA834] block">Season</span>
            <span className="text-neutral-700">Listes & Recettes</span>
          </h1>
          <p className="text-xl text-neutral-700 text-center mb-8 font-bold">
            La bonne saison dans votre assiette
          </p>
          <div className="flex justify-center pt-8">
            <a 
              href={playStoreUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={googlePlayImg}
                alt="Get it on Google Play"
                className="w-40 hover:opacity-90 transition-opacity"
              />
            </a>
          </div>
        </div>
      </div>

      <div className="mt-8 px-2">
        <h2 className="text-2xl font-bold text-center mb-6">
          <FaSeedling className="inline-block mr-2 text-season-green" />
          Au gré des saisons
        </h2>
        <div className="text-base text-gray-600 dark:text-gray-300 leading-relaxed flex flex-col gap-4 text-justify">
          <p>
            <strong className=" text-season-green">Season:</strong> L&R est une
            application intuitive pour gérer{" "}
            <strong>vos repas au quotidien</strong>. Créez ou{" "}
            <strong>importez facilement des recettes</strong> de saison depuis
            le web et ajoutez <strong>les ingrédients en un clic</strong> dans
            vos listes de courses.
          </p>
          <p>
            Grâce à <strong>un planificateur pratique</strong>, organisez vos
            repas pour toute la semaine en quelques étapes.{" "}
            <strong>Exportez ou importez vos données</strong> pour une gestion
            simple et sécurisée, idéale pour changer d'appareil ou créer des
            sauvegardes.
          </p>
        </div>
      </div>
    </div>
  );
} 